import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import { baseGrid, button, mq } from '../theme/mixins'
import AnimateIn from './AnimateIn'
import HeroImage from './HeroImage'
import InternalLink from './InternalLink'

const HomeHero = () => {
  const { home } = useStaticQuery(graphql`
    query {
      home: datoCmsHome {
        heroHeading
        heroBodyNode {
          childMarkdownRemark {
            html
          }
        }
        heroCta {
          ...InternalLinkFragment
        }
        heroImage {
          gatsbyImageData(layout: FULL_WIDTH, imgixParams: { q: 80 })
          alt
          focalPoint {
            x
            y
          }
        }
      }
    }
  `)
  const heroStyle = css`
    --row-1: min(max(33vw, 20vw + 18rem), 67vh);
    --row-2: max(5vw, 6rem);
    ${baseGrid}
    grid-template-rows: var(--row-1) var(--row-2) 1fr;
  `
  const imageWrapStyle = css`
    grid-row: 1 / 3;
  `
  const parallaxStyle = css`
    height: calc(var(--row-1) + var(--row-2) + var(--nav-menu-height));
  `
  const textboxWrapperStyle = css`
    grid-row: 2 / 4;
    grid-column: 3 / -3;
    z-index: 2;
    ${mq().ms} {
      grid-column: 2 / -2;
    }
  `
  const textboxStyle = css`
    background-color: white;
    text-align: center;
    padding: var(--gutter-sm) var(--gutter-xlg) var(--gutter-mlg);
    h1 {
      margin: 0.25em 0 0;
    }
    p {
      font-size: var(--fs-24);
      font-weight: 300;
      line-height: 1.5;
    }
    ${mq().m} {
      padding: var(--gutter-sm) var(--gutter-mlg) var(--gutter-mlg);
    }
  `
  const ctaStyle = css`
    ${button}
    font-size: var(--fs-18);
    display: inline-block;
    margin-top: 0.75em;
  `
  return (
    <section css={heroStyle}>
      <HeroImage
        wrapperCss={imageWrapStyle}
        innerCss={parallaxStyle}
        image={home.heroImage}
      />
      <AnimateIn
        wrapperCss={textboxWrapperStyle}
        innerCss={textboxStyle}
        initialInView
      >
        <h1>{home.heroHeading}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: home.heroBodyNode.childMarkdownRemark.html,
          }}
        />
        <InternalLink css={ctaStyle} data={home.heroCta[0]} />
      </AnimateIn>
    </section>
  )
}

export default HomeHero
