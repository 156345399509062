import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import React from 'react'
import { Helmet } from 'react-helmet'

import { colors } from '../theme/variables'
import LogoBackground from './LogoBackground'
import TestimonialsCarousel from './TestimonialsCarousel'

const HomeTestimonials = ({ metaData }) => {
  const { home } = useStaticQuery(graphql`
    query {
      home: datoCmsHome {
        testimonials {
          quote
          attribution
        }
      }
    }
  `)
  const sectionStyle = css`
    position: relative;
  `
  return (
    <section css={sectionStyle}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'LocalBusiness',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '5',
              ratingCount: '5',
              reviewCount: '5',
            },
            itemReviewed: {
              '@type': 'LocalBusiness',
              name: metaData.name,
              legalName: metaData.legalName,
              image: metaData.image.url,
              telephone: `[${metaData.telephone}]`,
              address: {
                '@type': 'PostalAddress',
                streetAddress: metaData.streetAddress,
                addressLocality: metaData.city,
                addressRegion: metaData.state,
                postalCode: metaData.postalCode,
                addressCountry: metaData.country,
              },
            },
            review: home.testimonials.map((testimonial, i) => ({
              '@type': 'Review',
              reviewBody: testimonial.quote,
              reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
              },
              author: {
                '@type': 'Person',
                name: testimonial.attribution,
              },
            })),
          })}
        </script>
      </Helmet>
      <LogoBackground color={rgba(colors.greenLight, 0.333)} clip />
      <TestimonialsCarousel testimonials={home.testimonials} />
    </section>
  )
}

export default HomeTestimonials
