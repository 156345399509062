import { css } from '@emotion/react'
import React from 'react'

import AnimateIn from './AnimateIn'
import Carousel from './Carousel'
import Testimonial from './Testimonial'

const TestimonialsCarousel = ({
  testimonials = [],
  ...props
}) => {
  const sliderStyle = css`
    min-height: 33vw;
  `
  return (
    <AnimateIn>
      <Carousel
        css={sliderStyle}
        itemArray={testimonials}
        Component={Testimonial}
        {...props}
      />
    </AnimateIn>
  )
}

export default TestimonialsCarousel
