import { css, keyframes } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import React, { useLayoutEffect, useRef, useState } from 'react'

import { mq } from '../theme/mixins'
import { colors } from '../theme/variables'

const HomePillars = () => {
  const { home } = useStaticQuery(graphql`
    query {
      home: datoCmsHome {
        pillars {
          icon {
            url
          }
          heading
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  const ref = useRef(null)
  const [inView, setInView] = useState(false)
  const observer =
    typeof window !== 'undefined' &&
    new IntersectionObserver(
      entries => {
        const [{ isIntersecting }] = entries
        if (isIntersecting) {
          setInView(true)
        }
      },
      {
        triggerOnce: true,
        rootMargin: '-5% 0%',
      }
    )
  useLayoutEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.disconnect()
    }
  })
  const sectionStyle = css`
    margin: 4rem var(--margin-outer) var(--gutter-md);
    padding: 3rem var(--gutter-md) var(--gutter-sm);
    border-top: 1px solid ${rgba(colors.grayDark, 0.25)};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gutter-lg);
    ${mq().ms} {
      grid-template-columns: 1fr;
      grid-gap: var(--gutter-md);
      padding-left: 0;
      padding-right: 0;
      padding-bottom: var(--gutter-lg)
    }
  `
  const animateIn = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(0, 50%, 0) scale3d(0.8, 0.8, 0.8);
    }
    100% {
      opacity: 1;
      transform: none;
    }
  `
  const pillarStyle = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    opacity: 0;
    ${inView &&
    css`
      animation: ${animateIn} 1200ms cubic-bezier(0.25, 0.75, 0.25, 1)
        forwards;
    `}
    &:nth-of-type(2) {
      animation-delay: 200ms;
    }
    &:nth-of-type(3) {
      animation-delay: 400ms;
    }
    img {
      width: 12rem;
    }
    h2 {
      font-size: var(--fs-24);
      font-weight: 400;
      margin: 0.75em 0 0.5em;
    }
    p {
      margin-top: 0;
    }
    ${mq().ms} {
      flex-direction: row;
      text-align: left;
      img {
        align-self: flex-start;
        margin-top: 1rem;
        width: 9rem;
        max-width: 33%;
        margin-right: 1.5rem;
      }
    }
  `
  return (
    <section css={sectionStyle} ref={ref}>
      {home.pillars.map(pillar => (
        <div key={pillar.heading} css={pillarStyle}>
          <img src={pillar.icon.url} alt={pillar.heading} />
          <div>
            <h2>{pillar.heading}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: pillar.bodyNode.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>
      ))}
    </section>
  )
}

export default HomePillars
