import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import HomeHero from '../components/HomeHero'
import HomeHighlights from '../components/HomeHighlights'
import HomePillars from '../components/HomePillars'
import HomeTestimonials from '../components/HomeTestimonials'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const IndexPage = () => {
  const { page, metaData, footer } = useStaticQuery(graphql`
    query {
      page: datoCmsHome {
        seo {
          title
          description
        }
      }
      metaData: datoCmsMetaDataModel {
        name
        brand
        legalName
        url
        logo {
          url
        }
        image {
          url
        }
        foundingDate
        description
        telephone
        streetAddress
        city
        state
        postalCode
        country
      }
      footer: datoCmsFooter {
        socials {
          url
        }
      }
    }
  `)
  return (
    <Layout fullLogo mainCss={{ paddingTop: 'var(--nav-menu-height)' }}>
      <Seo
        noSuffix
        title={page.seo?.title || 'Countryside Cabinets'}
        description={page.seo?.description}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'LocalBusiness',
            name: metaData.name,
            brand: metaData.brand,
            legalName: metaData.legalName,
            url: metaData.url,
            logo: metaData.logo.url,
            image: metaData.image.url,
            foundingDate: metaData.foundingDate,
            description: metaData.description,
            telephone: `[${metaData.telephone}]`,
            address: {
              '@type': 'PostalAddress',
              streetAddress: metaData.streetAddress,
              addressLocality: metaData.city,
              addressRegion: metaData.state,
              postalCode: metaData.postalCode,
              addressCountry: metaData.country,
            },
            sameAs: footer.socials.map(social => social.url),
          })}
        </script>
      </Helmet>
      <HomeHero />
      <HomePillars />
      <HomeHighlights />
      <HomeTestimonials metaData={metaData} />
    </Layout>
  )
}

export default IndexPage
