import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { baseGrid, button, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import { focalPoint } from '../utils/helpers'
import AnimateIn from './AnimateIn'
import ConditionalWrapper from './ConditionalWrapper'
import InternalLink from './InternalLink'
import ParallaxTranslate from './ParallaxTranslate'

const HomeHighlights = () => {
  const { home } = useStaticQuery(graphql`
    query {
      home: datoCmsHome {
        highlights {
          images {
            gatsbyImageData(
              imgixParams: { q: 75, ar: "5:3", fit: "crop" }
              width: 960
            )
            gridImageData: gatsbyImageData(
              imgixParams: { q: 75, ar: "5:3", fit: "crop" }
              width: 480
            )
            alt
            focalPoint {
              x
              y
            }
          }
          heading
          bodyNode {
            childMarkdownRemark {
              html
            }
          }
          link {
            ...InternalLinkFragment
          }
        }
      }
    }
  `)
  const sectionStyle = css`
    display: grid;
    grid-gap: var(--gutter-lg);
    padding: var(--gutter-lg) 0 calc(var(--gutter-lg) * 1.5);
    background-color: ${colors.greenLighter};
  `
  const highlightStyle = css`
    ${baseGrid}
    grid-template-rows: var(--gutter-lg) 1fr var(--gutter-lg);
    min-height: 36vw;
    ${mq().ms} {
      min-height: none;
      grid-template-rows: auto 25vw auto;
    }
  `
  const imageWrapStyle = css`
    grid-column-start: 1;
    grid-column-end: span 8;
    grid-row: 1 / 4;
    position: relative;
    overflow: hidden;
    .gatsby-image-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    img {
      min-height: 100%;
      min-width: 100%;
    }
    &.even {
      grid-column-start: 7;
    }
    ${mq().ms} {
      grid-row: 1 / 3;
      grid-column: 1 / span 10;
      height: 0;
      padding-bottom: 100%;
      &.even {
        grid-column-start: 5;
      }
    }
    ${mq().s} {
      grid-column-end: span 12;
      &.even {
        grid-column-start: 3;
      }
    }
  `
  const multiImageWrapStyle = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;
    > .multi-image-wrapper {
      position: relative;
      overflow: hidden;
    }
    ${mq().ms} {
      height: auto;
      padding-bottom: 0;
      > .multi-image-wrapper {
        height: 0;
        padding-bottom: 100%;
      }
    }
  `
  const textboxWrapperStyle = css`
    grid-column-start: 8;
    grid-column-end: span 6;
    grid-row: 2 / 3;
    display: flex;
    z-index: 2;
    align-self: center;
    &.even {
      grid-column-start: 2;
    }
    ${mq().ms} {
      align-self: flex-start;
      grid-column: 3 / span 11;
      grid-row: 2 / 4;
      margin-bottom: var(--gutter-md);
      --translate-factor: 50;
    }
  `
  const textboxStyle = css`
    background-color: white;
    padding: var(--gutter-mlg);
    h2 {
      margin: 0 0 0.5em;
      font-size: var(--fs-36);
    }
    p {
      font-size: var(--fs-24);
      font-weight: 300;
      line-height: 1.5;
      ${mq().ms} {
        font-size: var(--fs-21);
      }
    }
  `
  const linkStyle = css`
    ${button}
    font-size: var(--fs-18);
    margin: 1.75em 0 0;
    ${mq().ms} {
      font-size: var(--fs-15);
    }
  `
  return (
    <section css={sectionStyle}>
      {home.highlights.map((highlight, i) => {
        const multiImage = highlight.images.length > 1
        const even = i % 2
        return (
          <AnimateIn innerCss={highlightStyle} key={i}>
            <div
              css={[imageWrapStyle, multiImage && multiImageWrapStyle]}
              className={even ? 'even' : 'odd'}
            >
              {highlight.images.map((image, i) => (
                <ConditionalWrapper
                  key={i}
                  condition={multiImage}
                  wrapper={children => (
                    <div className="multi-image-wrapper">
                      {children}
                    </div>
                  )}
                >
                  <GatsbyImage
                    image={
                      multiImage
                        ? image.gridImageData
                        : image.gatsbyImageData
                    }
                    key={i}
                    alt={image.alt || ''}
                    objectPosition={focalPoint(image.focalPoint)}
                  />
                </ConditionalWrapper>
              ))}
            </div>
            <ParallaxTranslate
              wrapperCss={textboxWrapperStyle}
              innerCss={textboxStyle}
              className={even ? 'even' : 'odd'}
            >
              <h2>{highlight.heading}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: highlight.bodyNode.childMarkdownRemark.html,
                }}
              />
              <InternalLink data={highlight.link[0]} css={linkStyle} />
            </ParallaxTranslate>
          </AnimateIn>
        )
      })}
    </section>
  )
}

export default HomeHighlights
