import { css } from '@emotion/react'
import React from 'react'

import { allCaps, baseGrid, mq } from '../theme/mixins'
import { colors } from '../theme/variables'

const Testimonial = ({ data, active, ...props }) => {
  const style = css`
    ${baseGrid}
    width: 100vw;
  `
  const contentStyle = css`
    grid-column: 4 / -4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: var(--gutter-xlg) 0;
    ${mq().s} {
      grid-column: 3 / -3;
    }
    > blockquote {
      font-size: var(--fs-24);
      font-weight: 300;
      font-style: italic;
      line-height: 1.5;
      margin: 0;
      ${mq().s} {
        font-size: var(--fs-21);
      }
    }
    > figcaption {
      ${allCaps}
      font-weight: 500;
      font-size: var(--fs-15);
      margin-top: 2em;
      ${mq().s} {
        font-size: var(--fs-13);
      }
    }
    > .stars {
      align-self: center;
      display: flex;
      font-size: var(--fs-24);
      margin-bottom: 1em;
      > svg {
        height: 1em;
        width: 1em;
        margin: 0 0.125em;
        polygon {
          fill: ${colors.green};
        }
      }
    }
  `
  return (
    <div css={style} {...props}>
      <div css={contentStyle}>
        <div className="stars">
          {Array.from({ length: 5 }, (_, i) => (
            <svg key={i} width="24px" height="22px" viewBox="0 0 24 22">
              <polygon points="12 18.9926401 4.94657697 22.7082039 6.30066063 14.8518276 0.587321804 9.29179607 8.47761455 8.15185236 12 1 15.5223854 8.15185236 23.4126782 9.29179607 17.6993394 14.8518276 19.053423 22.7082039" />
            </svg>
          ))}
        </div>
        <blockquote>{data.quote}</blockquote>
        <figcaption>– {data.attribution}</figcaption>
      </div>
    </div>
  )
}

export default Testimonial
